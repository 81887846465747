// styles for congress page


// subscribe button
.button {
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    margin: 4px 2px;
    cursor: pointer;
}
  
.button1 {
    background-color: #4a9234;
    border-radius: 2rem
}

.congres_container a:link,
.button_link a:link{
    color: white;
    text-decoration: none;
}

.congres_container a:visited,
.button_link a:visited {
    color: white;
    text-decoration: none;
}

.congres_container a:hover,
.button_link a:hover {
    color: white;
    text-decoration: underline;
}

.congres_container a:active,
.button_link a:active {
    color: white;
    text-decoration: underline;
}

.session-title {
    color: white
}

/*************************
 * GRID SCHEDULE LAYOUT
 *************************/
 @media screen and (min-width:700px) {
	.schedule {
		display: grid;
		grid-gap: 0em;
		grid-template-rows:
			[tracks] auto
            [time-0900] 0.33fr
            [time-0910] 0.33fr
            [time-0920] 0.33fr
            [time-0930] 0.33fr
            [time-0940] 0.33fr
            [time-0950] 0.33fr
            [time-1000] 0.33fr
            [time-1010] 0.33fr
            [time-1020] 0.33fr
            [time-1030] 0.33fr
            [time-1040] 0.33fr
            [time-1050] 0.33fr
            [time-1100] 0.33fr
            [time-1110] 0.33fr
            [time-1120] 0.33fr
            [time-1130] 0.33fr
            [time-1140] 0.33fr
            [time-1150] 0.33fr
            [time-1200] 0.33fr
            [time-1210] 0.33fr
            [time-1220] 0.33fr
            [time-1230] 0.33fr
            [time-1240] 0.33fr
            [time-1250] 0.33fr
            [time-1300] 0.33fr
            [time-1310] 0.33fr
            [time-1320] 0.33fr
            [time-1330] 0.33fr
            [time-1340] 0.33fr
            [time-1350] 0.33fr
            [time-1400] 0.33fr
            [time-1410] 0.33fr
            [time-1420] 0.33fr
            [time-1430] 0.33fr
            [time-1440] 0.33fr
            [time-1450] 0.33fr
            [time-1500] 0.33fr
            [time-1510] 0.33fr
            [time-1520] 0.33fr
            [time-1530] 0.33fr
            [time-1540] 0.33fr
            [time-1550] 0.33fr
            [time-1600] 0.33fr
            [time-1610] 0.33fr
            [time-1620] 0.33fr
            [time-1630] 0.33fr
            [time-1640] 0.33fr
            [time-1650] 0.33fr
            [time-1700] 0.33fr
            [time-1710] 0.33fr
            [time-1720] 0.33fr
            [time-1730] 0.33fr
            [time-1740] 0.33fr
            [time-1750] 0.33fr
            [time-1800] 0.33fr
            [time-1810] 0.33fr
            [time-1820] 0.33fr
            [time-1830] 0.33fr
            [time-1840] 0.33fr
            [time-1850] 0.33fr
            [time-1900] 0.33fr
            [time-1910] 0.33fr
            [time-1920] 0.33fr
            [time-1930] 0.33fr;
			/* Note 1:
			Use 24hr time for gridline names for simplicity

			Note 2: Use "auto" instead of "1fr" for a more compact schedule where height of a slot is not proportional to the session length. Implementing a "compact" shortcode attribute might make sense for this!
			Try 0.5fr for more compact equal rows. I don't quite understand how that works :)
			*/
		
		grid-template-columns:
			[times] 4em
			[track-1-start] 1fr
			[track-1-end track-2-start] 1fr
			[track-2-end track-3-start] 1fr
			[track-3-end track-4-start] 1fr
			[track-4-end track-5-start] 1fr
            [track-5-end track-6-start] 1fr
            [track-6-end];
	}
}

.time-slot {
	grid-column: times;
}

.track-slot {
	display: none; /* hidden on small screens and browsers without grid support */
}

@supports( display:grid ) {
	@media screen and (min-width:700px) {
		.track-slot {
			display: block;
			padding: 10px 5px 5px;
			position: sticky;
			top: 0;
			z-index: 5;
			background-color: rgba(255,255,255,.9);
		}
	}
}

/* Small-screen & fallback styles */
.session {
	margin-bottom:  1em;
}

@supports( display:grid ) {
	@media screen and (min-width: 700px) {
		.session {
			margin: 0;
            margin-bottom: 1em;
            margin-right: 1em;
		}	
	}
}

/*************************
 * VISUAL STYLES
 * Design-y stuff not particularly important to the demo
 *************************/
/*body {
	padding: 50px;
	max-width: 1100px;
	margin: 0 auto;
	line-height: 1.5;
}*/

.session {
	padding: .5em;
	border-radius: 2px;
	font-size: 14px;
	box-shadow:
		rgba(255,255,255,.6) 1px 1px 0,
		rgba(0,0,0,.3) 4px 4px 0;
}

.session-title,
.session-time,
.session-track,
.session-presenter {
	display: block;
}

.session-title,
.time-slot {
	margin: 0;
	font-size: 1em;
}

.session-title a {
	color: #fff;
	text-decoration-style: dotted;
	
	&:hover {
		font-style: italic;
	}
	
	&:focus {
		outline: 2px dotted rgba(255,255,255,.8);
	}
}

.track-slot,
.time-slot {
	font-weight: bold;
	font-size:.75em;
}

.track-0 {
	background-color: #0b75bb;
	color: #fff;
}

.track-00 {
	background-color: #4a9234;
	color: #fff;
}

.track-1 {
	background-color: #0b75bb;
	color: #fff;
}

.track-2 {
	background-color: #0b75bb;
	color: #fff;
}

.track-3 {
	background-color: #0b75bb;
	color: #fff;
}

.track-4 {
	background-color: #0b75bb;
	color: #fff;
}

.track-5 {
	background-color: #0b75bb;
	color: #fff;
}

.track-6 {
	background-color: #0b75bb;
	color: #fff;
}

.text {
	max-width: 750px;
	font-size: 18px;
	margin: 0 auto 50px;
}

.meta {
	color: #555;
	font-style: italic;
}

.meta a {
	color: #555;
}

hr {
	margin: 40px 0;
}


/***************************
    * Hover function *
***************************/

.hover {
    /*position: relative;*/
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
.hover .hovertext {
    /*display: none;
    min-width: 120px;
    max-width: 700px;
    //width: 50vw;
    //margin-top: 1rem;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 10px;
    //left: 50vw;
    //transform:translate(-25vw, 0); 
    Position the tooltip
    position: absolute;
    z-index: 1;*/
    position: fixed;
    //display: none;
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.8);
    color: white;
    z-index: 6;
    visibility: hidden;
    padding: 10px;
    border-radius: 10px;
  }
  
.hover:hover {
    cursor: pointer;
}

.hover:hover .hovertext, .hover:active .hovertext {
    visibility: visible;
  }

#session_event_details {
    position: fixed;
    display: none;
    width: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,.8);
    color: white;
    z-index: 6;
    //visibility: hidden;
    padding: 10px;
    border-radius: 10px;
}

#session_event_details button{
    float: right;
    margin-top: 5px;
}